import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Job } from './job';

/**
 * Common any type
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Any = any;

export type AnyObject = { [key: string]: string };

export type AnyFunction = (...args: Any[]) => Any;

export type AsyncFunction = (...args: Any[]) => Promise<Any>;

export type Primitive = number | string | null | undefined;

export enum Role {
  Reader = 'reader',
  Writer = 'writer',
  Manager = 'manager',
  Admin = 'admin',
}

export enum ProjectRole {
  Reader = 'reader',
  Writer = 'writer',
  Manager = 'manager',
}

export enum TenantRole {
  User = 'user',
  Manager = 'manager',
  Admin = 'admin',
}

export type Project = {
  name: string;
  orgName: string | null;
  role: Role;
  clusterName: string;
};

export type NoOrganization = null;

export namespace Organization {
  export type Name = string | NoOrganization;

  export type Model = {
    name: string;
    role: TenantRole;
  };
}

export type SidebarItem = {
  regularIcon: IconProp;
  solidIcon: IconProp;
  name: string;
  path: string | string[];
};

export namespace Cluster {
  export type ResourcePoolType = {
    cpu: number;
    diskSize: number;
    maxSize: number;
    memory: number;
    minSize: number;
    name: string;
    intelGpu?: number;
    amdGpu?: number;
    nvidiaGpu?: number;
  };

  export type ResourcePreset = {
    amdGpu?: number;
    cpu: number;
    creditsPerHour: string;
    intelGpu?: number;
    isPreemptible: boolean;
    isPreemptibleNodeRequired: boolean;
    memory: number;
    memoryMb: number;
    name: string;
    nvidiaGpu?: number;
    preemptibleNode: boolean;
    schedulerEnabled: boolean;
    resourcePoolNames?: string[];
    availableResourcePoolNames: string[];
  };

  export type StorageVolume = {
    name: string;
    creditsPerHourPerGb: string;
  };

  export type Model = {
    name: string;
    orgs: Array<string | null>;
    projects: Project[];
    metricsUrl: string;
    monitoringUrl: string;
    secretsUrl: string;
    storageUrl: string;
    disksUrl: string;
    bucketsUrl: string;
    registryUrl: string;
    usersUrl: string;
    resourcePoolTypes: ResourcePoolType[];
    resourcePresets: ResourcePreset[];
    storageVolumes: StorageVolume[];
    location?: string;
    logoUrl?: string;
  };
}

export type ModalProps = {
  closeModal?: () => void;
};

export type FieldProps = {
  required?: boolean;
  className?: string;
  containerClassName?: string;
  variant?: 'floating' | 'static';
  label?: string;
  showError?: boolean;
  error?: string;
  note?: string;
  children?: React.ReactNode;
};

export type ResponseError = {
  error: string;
};

/**
 * Used for dayjs
 */
export type DateFormat = string;

export type Secret = {
  key: string;
  orgName: Organization.Name;
  owner: string;
  projectName: string;
};

export type Disk = {
  id: string;
  name: string;
  createdAt: DateFormat;
  lastUsage: null;
  lifeSpan?: number;
  owner: string;
  status: string;
  storage: number;
  usedBytes: null;
  uri?: string;
};

export enum Permission {
  Read = 'read',
  Write = 'write',
  Manage = 'manage',
}

export type PermissionModel = {
  uri: string;
  action: Permission;
};

export type PermissionUri = 'blob:' | 'cluster:';

export enum Mode {
  ReadWrite = 'rw',
  ReadOnly = 'ro',
}

export namespace Image {
  export type Name = string;

  export type Manifest = {
    imageSizeBytes: string;
    mediaType: string;
    tag: string[];
    timeCreatedMs: string;
    timeUploadedMs: string;
  };

  export type Model = {
    child: string[];
    manifest: { [key: string]: Manifest };
    name: Name;
    tags: string[];
  };
}

export type JobNavigatorItem = {
  title: string;
  name: string;
};

export enum VolumeType {
  Secret = 'secret',
  Storage = 'storage',
  Disk = 'disk',
}

export namespace Flow {
  export type BakeLastAttempt = {
    id: string;
    bakeId: string;
    number: number;
    createdAt: DateFormat;
    result: Job.Status;
    executorId: string;
    configsMeta: AnyObject;
  };

  export type BakeGraphs = {
    [key: string]: {
      [key: string]: string[];
    };
  };

  export type Bake = {
    id: string;
    projectId: string;
    batch: string;
    createdAt: DateFormat;
    tags: string[];
    name: string | null;
    graphs: BakeGraphs;
    lastAttempt: BakeLastAttempt;
  };

  export type BakeTask = {
    yamlId: string;
    outputs: null & { [key: string]: string };
    statuses: Job.History[];
    rawId: string | null;
  };

  export type Model = {
    cluster: string;
    id: string;
    name: string;
    orgName: Organization.Name;
    owner: string;
    projectName: string;
  };
}

export type Balance = {
  spentCredits: string;
  credits?: string;
};

export enum ViewType {
  Grid,
  List,
}

export enum Hostname {
  Scottdc = 'scottdc',
  Novoserve = 'novoserve',
  Imhpc = 'imhpc',
  Megaport = 'megaport',
  Wwt = 'wwt-pg',
}

export namespace OutletContext {
  export type Job = {
    setTabTitle: (tab: Job.Tab) => void;
  };
}

export type TelemetryData = {
  /**
   * Actially can be `undefined`
   */
  gpuMemory: number;
  /**
   * Actially can be `undefined`
   */
  gpuDutyCycle: number;
  cpu: number;
  memory: number;
  timestamp: number;
};

/**
 * Is used for observability tab to manage empty chart columns
 *
 * todo: try to optimize, DRY
 */
export type TelemetryItem = {
  /**
   * Actially can be `undefined`
   */
  gpuMemory: number | null;
  /**
   * Actially can be `undefined`
   */
  gpuDutyCycle: number | null;
  cpu: number | null;
  memory: number | null;
  timestamp: number;
};
